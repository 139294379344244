import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { boolean, object, string } from 'yup';

import {
    ControlledCheckbox,
    ControlledInput,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    IParams,
    Page,
    RemoveModal,
    StatusCode,
} from '../../../shared';
import { UserRoles } from '../../components';
import { usersHook } from '../../hooks';
import { userFromFormMapper } from '../../mappers';
import { IUserForm } from '../../models';

export const UserEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: user, isLoading } = usersHook.useGetUser(id);
    const { mutateAsync: saveUser } = usersHook.useSaveUser();
    const { mutateAsync: deleteUser } = usersHook.useDeleteUser();

    const onDelete = useCallback(async () => {
        await deleteUser(id);
        navigate('/users');
    }, [deleteUser, navigate, id]);

    const schema = object().shape({
        email: string().email(t('invalidEmail')).required(t('emailRequired')),
        firstName: string().required(t('firstNameRequired')),
        lastName: string().required(t('lastNameRequired')),
        enabled: boolean(),
    });

    const form = useForm<IUserForm>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (user) {
            form.reset({ ...user, enabled: user.status === 'enabled' });
        }
    }, [user, form.reset]);

    const onSubmit = async (item: IUserForm) => {
        try {
            await saveUser(userFromFormMapper(item, user));
            navigate('/users');
        } catch (err) {
            if ((err as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                form.setError('email', {
                    message: t('emailUniqErr'),
                });
            } else {
                throw err;
            }
        }
    };

    const actions = [
        <Button onClick={() => navigate('/users')} color="secondary">
            {t('cancel')}
        </Button>,
        <>
            {id && (
                <RemoveModal
                    handleDelete={onDelete}
                    button={
                        <Button color="primary" variant="outlined" data-testid="deleteUser">
                            {t('delete')}
                        </Button>
                    }
                    title={t('userDeleteWarningTitle')}
                    text={t('userDeleteWarningText')}
                />
            )}
        </>,
        <Button type="submit" variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('save')}
        </Button>,
    ];

    return (
        <Page
            title={id ? `${user?.firstName || ''} ${user?.lastName || ''}` : t('newUser')}
            actions={actions}
            loading={isLoading}
        >
            <FormProvider {...form}>
                <FormCard actions={actions.slice().reverse()} handleSubmit={form.handleSubmit(onSubmit)}>
                    <FormCardSectionComponent title={t('data')}>
                        <FormGrid
                            items={[
                                <ControlledInput name="email" label={t('email')} required />,
                                <ControlledInput name="firstName" label={t('firstName')} required />,
                                <ControlledInput name="lastName" label={t('lastName')} required />,
                            ]}
                        />
                    </FormCardSectionComponent>
                    <FormCardSectionComponent title={t('status')}>
                        <ControlledCheckbox name="enabled" label={t('enabled')} />
                    </FormCardSectionComponent>
                    {id && (
                        <FormCardSectionComponent title={t('roles')}>
                            <UserRoles userId={id} />
                        </FormCardSectionComponent>
                    )}
                </FormCard>
            </FormProvider>
        </Page>
    );
};
