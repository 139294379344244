import { ISaveUser, IUser, IUserForm } from '../models';

export const userFromFormMapper = (item: IUserForm, existingUser?: IUser): ISaveUser => {
    return {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        sub: existingUser ? existingUser.sub : item.email,
        status: item.enabled ? 'enabled' : 'pending',
    };
};
